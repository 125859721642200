import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentful from '../../hooks/useContentful'
import Breadcrumb from '../../common/Breadcrumb';
import { translate } from '../../utils/translate';
import LoadingScreen from '../../common/LoadingScreen';
import { localise } from '../../utils/localize';
import Cta from '../../common/Cta';

export default function Privacypolicy() {
    const {data, isLoading, error} = useContentful("privacypolicy")
    console.log("policy", data);
    if(isLoading) return <LoadingScreen/>
  return (
    <>
    {
        data &&
        <>
    <Breadcrumb title={translate("policy")} />
    <div className="ac-feature-area" style={{margin:'40px 0px'}}>
      <div className="container">
    <div style={{padding:'45px 25px',  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius:'5px'}}>{localise(documentToReactComponents(data[0].fields.policycontent),documentToReactComponents(data[0].fields.privacyContentArb))}</div>
    </div>
   
    </div>
    <Cta/>
    </>
    }
    </>
  )
}
