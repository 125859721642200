import React from "react";

function UnderConstruction() {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div>
        <h1 className="text-center">Under Construction</h1>
        <p className="text-center">
          This page is Under Construction.Will be Up in sometime
        </p>
      </div>
    </div>
  );
}

export default UnderConstruction;
